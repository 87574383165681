import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { ItemTitle, Detail } from "../components/works/style"
import { Text, PageWrapper } from "../components/main"
import { Items, Item } from "../components/works/style"
import {
  Flow,
  Number,
  ProductItem,
  ProductTitle,
  ProductDetail,
  Img,
} from "../styles/service"
import Button from "../components/button"

const Service = () => (
  <Layout>
    <SEO title="Service" />
    <PageWrapper>
      <h1>Audio Contents Production</h1>
      <h3>音声コンテンツ制作</h3>
      <Text style={{ marginTop: 30 }}>
        私たちはクライアントの事業にしっかりと向き合い、理解をした上で、プランニング〜脚本制作〜音声収録までワンストップでコミットします。
      </Text>
      <ProductItems />
      <Text style={{ width: "100%", textAlign: "center" }}>
        お見積もりは無料です。まずはお気軽にお問い合わせください。
      </Text>
      <Link to="/contact" style={{ margin: "auto" }}>
        <Button>Contact us</Button>
      </Link>
      <h1 style={{ marginTop: 80 }}>Pratforms</h1>
      <h3 style={{ marginBottom: 30 }}>プラットフォーム事業</h3>
      <ServiceItems />
    </PageWrapper>
  </Layout>
)

export default Service

const numbers = ["01", "02 ", "03", "04", "05", "06"]
const productTitles = [
  "ヒアリング",
  "お見積もり",
  "お申し込み",
  "お打ち合わせ",
  "制作開始",
  "納品",
]
const productTexts = [
  "まずはお気軽にお問合せください。コンテンツの用途やイメージ、納期などをお聞かせいただきながら具体的な制作内容を決めていきます。",
  "お問い合わせ・ヒアリング内容を元に、お見積もりをさせていただきます。ご予算に合いそうでしたら、ご連絡をお待ちしております。",
  "内容にご納得いただけましたらご契約となり、制作に進ませていただきます。",
  "改めて目的やターゲット、スケジュールなどを確認します。",
  "具体的な脚本制作＞音声収録を行います。クライアントのご希望に沿って、変更・追加・修正を行いブラッシュアップさせていきます。",
  "完成した音声コンテンツをご確認いただき、問題がなければ音声コンテンツをデータにて納品させていただきます。",
]

const ProductItems = () => {
  return (
    <Flow>
      {productTitles.map((productTitle, i) => {
        return (
          <ProductItem>
            <Number>{numbers[i]}</Number>
            <ProductTitle>{productTitle}</ProductTitle>
            <ProductDetail>{productTexts[i]}</ProductDetail>
          </ProductItem>
        )
      })}
    </Flow>
  )
}

const links = ["https://works.writone.com/", "https://www.writone.com/"]
const srcs = [
  "https://storage.googleapis.com/writone-ja-admin/banner/works-min.jpg",
  "https://storage.googleapis.com/writone-ja-admin/banner/about-min.jpg",
]
const titles = ["Writone Works", "Writone "]
const texts = [
  "声優4000名、ライター3000名の登録者数を超えるライトーンから厳選された高スキルなクリエイターに、直接お仕事の依頼ができるサービスです。納期・価格・細かいニーズにも対応でき、お客様のご要望に最適なご依頼が可能です。",
  "ライターが投稿した小説・脚本を声優が音声化し、オーディオブックとして配信するサービスです。誰でも気軽に聴いて楽しむことができ、また、クリエイターとしてコンテンツを投稿することもできます。なかなか活躍できていないライター、アクターに新たな活躍の場と収入源を提供するためにつくられたライトーン。あなたの居場所のひとつになれば幸いです。",
]

const ServiceItems = () => {
  return (
    <Items>
      {titles.map((title, i) => {
        return (
          <Item>
            <a
              href={links[i]}
              target="_blank"
              rel="noopener noreferrer"
              key={i}
            >
              <Img src={srcs[i]} alt="worksImage" />
            </a>
            <ItemTitle>{title}</ItemTitle>
            <Detail>{texts[i]}</Detail>
          </Item>
        )
      })}
    </Items>
  )
}
