import styled from "styled-components"
import { RowCenter, ColCenter } from "../components/main"

export const Flow = styled(ColCenter)`
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
`

export const ProductItem = styled(ColCenter)`
  width: calc(100% - 40px);
  background-color: #4b8fe2;
  padding: 30px 20px 20px;
  border-radius: 20px;
  margin: 10px 0;
  @media screen and (min-width: 768px) {
    width: calc(46% - 40px);
    max-width: 400px;
    min-height: 270px;
  }
  @media screen and (min-width: 1280px) {
    max-width: 300px;
  }
`

export const Number = styled(RowCenter)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  font-size: 2.4rem;
  color: #4b8fe2;
  font-family: "Montserrat";
  margin-bottom: 20px;
`

export const ProductTitle = styled.span`
  font-size: 2rem;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
`

export const ProductDetail = styled.span`
  font-size: 1.4rem;
  margin: 5px 0 30px;
  color: white;
  @media screen and (min-width: 768px) {
    font-size: 1.2rem;
  }
`
export const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 20px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
  margin: 10px 0 15px;
  transition: all 0.2s;
  :hover {
    filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.25));
  }
`
